<template>
    <a-layout style="min-height:100%;">
        <a-layout-content style="height:100%;">
            <div class="yj-conten" style="min-height: calc(100% - 48px);width:calc(100% -32px);min-width:max-content">
                <a-layout>
                    <a-layout-header style="background-color:transparent; margin-bottom:14px;height:auto;padding:0">
                    </a-layout-header>
                    <billsdetail></billsdetail>
                </a-layout>
            </div>
        </a-layout-content>
    </a-layout>
</template>
<script>
    import billsdetail from './components/billsdetail'
    export default {
        name: "FinancialModule_Expend",
        data() {
            return {
            };
        },
        components: {
            billsdetail
        },
        methods: {
        },
        computed: {
            //计算属性
        },
        watch: {
            //侦听属性
        },
        beforeCreate: function () {

        },
        created: function () {
            //生命周期，创建完成
        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {

        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        }
    };
</script>